@import "_variables";
.page {
    z-index: 10;
    position: absolute;
    left: -100%;
    top: 2%;
    background-color: rgba(200, 200, 200, .7);
    width: 96%;
    height: 100%;
    overflow-x: auto;
}

#myPlayerID_html5_api {
    //filter: blur(10px);
}

#IntroCard {
    z-index: 10;
    position: absolute;
    top: 3%;
    width: 100%;
    align-content: center;
    //border:2px solid red;
    overflow-x: auto;
    overflow-y: auto;
    opacity: 0;
    display: none;
}

.card {
    margin-left: 10px;
    float: left;
    font-size: em(11);
}

.li {
    margin-left: 10px;
    float: left;
    font-size: em(1);
}

#Xfullscreen {
    //z-index: 100;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background: none;
}